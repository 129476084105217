<template>
    <div class="bg">
        <el-form label-width="0">
            <div>
                <el-form-item label="">
                    <el-input :rows="8" type="textarea" v-model="content" placeholder="记录你美丽的点点滴滴（不多于500个字）" maxlength="500" show-word-limit :clearable=true></el-input>
                </el-form-item>
            </div>
            <el-form-item label="">
                <div class="img-box">
                    <div class="img" :key="i" v-for="(m, i) in imgList">
                        <img :src="rImg(m.url)" />
                        <i class="el-icon-error" @click.stop="rmImg(i)"></i>
                    </div>
                    <div class="add img" @click="selectFile()">
                        <i class="el-icon-plus"></i>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div class="flex-c">
            <el-button style="width: 70vw;" round type="primary" @click="submit()">立即发布</el-button>
        </div>
        <input type="file" multiple="true" @change="getImgs" ref="img" style="display: none" accept="image/*" />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {},
        data() {
            return {
                imgList: [],
                content: '',
            };
        },
        created() {},
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // ...mapMutations([])
            // 确定发布
            async submit() {
                if (!this.content) {
                    this.$y_msg("请输入内容");
                    return;
                }
                let obj = {
                    userid: this.$y_getKey('userid'),
                    type: 0,
                    content: this.content,
                };

                this.$y_loading();

                let arr = [];
                this.imgList.forEach((v) => {
                    arr.push(v.file);
                });
                if (arr.length) {
                    obj.masterimg = await this.$y_upImgs(arr);
                    if (!obj.masterimg) {
                        this.$y_loadingClose();
                        return;
                    }
                }

                let {
                    code,
                    msg
                } = await this.$y_ajax('home/Dymanic/adddymanic', obj);
                this.$y_loadingClose();
                this.$y_msg(msg);
                if (code == 200) {
                    this.imgList = [];
                    this.content = ''
                }
            },
            // 选择文件
            selectFile() {
                this.$refs.img.click();
            },
            // 转换图片
            getImgs() {
                let files = this.$refs.img.files;
                for (let i = 0, len = files.length; i < len; i++) {
                    let u = window.URL.createObjectURL(files[i]);
                    this.imgList.push({
                        url: u,
                        file: files[i],
                    });
                }
            },
            // 移除图片
            rmImg(i) {
                this.imgList.splice(i, 1);
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        },
    };
</script>

<style lang="less" scoped>
    .bg {
        background: #fff;
        padding: 10px;
    }

    .tag {
        margin-right: 10px;
    }

    .input-new-tag {
        width: auto;
    }

    .tag-box {
        display: -webkit-box;
        overflow-x: auto;
        // width: 50vw;
        width: 100%;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 5px;
            background: #f3f3f3;
        }

        &::-webkit-scrollbar-thumb {
            background: #aaa;
            border-radius: 7px;
        }

        .t {
            border-radius: 20px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    /deep/.tip-box {
        li {
            line-height: 1 !important;
            border-bottom: 1px solid #f3f3f3;
            padding-top: 7px;
            padding-bottom: 7px;

            &:last-child {
                border-bottom: 0;
            }

            .des {
                margin-top: 5px;
                color: #aaa;
                font-size: 12px;
            }
        }
    }

    .img-box {
        display: flex;
        flex-wrap: wrap;

        .img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            box-sizing: border-box;
            overflow: hidden;
            object-fit: cover;
            cursor: pointer;
            position: relative;

            &.add {
                border: 1px dashed #aaa;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .el-icon-error {
                position: absolute;
                top: 5px;
                right: 5px;
                color: #aaa;
                font-size: 20px;
            }

            &.video {
                width: auto;
                position: relative;

                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }

                .cover {
                    background: rgba(0, 0, 0, 0.5);
                    text-align: center;
                    color: #ccc;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-size: 14px;
                    width: 100%;
                }
            }
        }
    }

    /* 合编 */
    .mix-user-ul {
        max-height: 200px;
        overflow-y: auto;

        .list {
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;

            .name {
                margin: 0 10px;

            }

            i {
                font-size: 25px;
            }

            .el-icon-circle-plus {
                color: #FD5621;
            }

            .el-icon-remove {
                color: #aaa;
            }
        }
    }

    .ready-user {
        padding-top: 10px;
        background: #fafafa;
    }
</style>